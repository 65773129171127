import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";


import Avatar from "../avatar";

import ClientsService from "../../services/clientsService";
import ClientLocationsService from "../../services/clientLocationsService";
import { getFullUserName, scrollToTop, getDateString, getTimeString, getDate, getYouTubeId } from "../../utils";

import AppointmentsService from '../../services/appointmentsService';
import LoadingCtrl from '../loadingCtrl';

import moment from "moment";
import CalendarsService from '../../services/calendarsService';
import UsersService from '../../services/usersService';
import VisitMotivesService from '../../services/visitMotivesService';
import LoggingService from '../../services/loggingService';
import { LogItemType } from '../../models/logItem';
import BookingPanel from '../bookingPanel';
import CampaignsService from '../../services/campaignsService';
import ClonrService from '../../services/clonrService';
import VideoPlayerCtrl from '../videoPlayerCtrl';
import { VideoGroup } from '../../models/clonRVideo';
import VideoGroupPlayerCtrl from '../videoGroupPlayerCtrl';
import LandingPageData from '../../models/landingPageData';
import Fab from '@mui/material/Fab';
import AiChatDialog from '../aiChatDialog';
import AiPhoneChatCtrl from '../aiPhoneChatCtrl';
import PatientsService from '../../services/patientsService';
// import { analytics } from '../database';


export default function AppointmentPage() {

    const [isLoading, setIsLoading] = useState(true);
    const [isBooking, setIsBooking] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);

    const [client, setClient] = useState<any>({ publicTransports: [], accessInformation: [], openingHours: [] });
    const [location, setLocation] = useState<any>(null);
    const [locationLogoUrl, setLocationLogoUrl] = useState("");
    const [doctor, setDoctor] = useState<any>(null);
    const [appointment, setAppointment] = useState<any>(null);
    const [selectedVisitMotive, setSelectedVisitMotive] = useState<any>(null);
    const [appointments, setAppointments] = useState<any[]>([]);
    const [videoGroup, setVideoGroup] = useState<VideoGroup | null>(null);

    const [showBookingConfirmation, setShowBookingConfirmation] = useState(false);

    const [showSnoozeMessage, setShowSnoozeMessage] = useState(false);

    const [landingPageData, setLandingPageData] = useState<any>(null);

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const [isChatButtonVisisble, setIsChatButtonVisisble] = useState(false);
    const [isChatDialogVisible, setIsChatDialogVisible] = useState(false);


    let { clientId, locationId, appointmentId, status } = useParams();


    useEffect(() => {
        setIsLoading(true);
        setIsBooking(false);
        setIsDeclining(false);

        loadAllData();

        setIsChatButtonVisisble(true);

        // analytics.logEvent("page_view", {page_title: "Appointment"});

    }, []);

    useEffect(() => {
        scrollToTop();
    }, [clientId, locationId, appointmentId]);

    async function loadAllData() {

        if (clientId && locationId && appointmentId) {

            // we only log a page visit again, if the patient returns after one or more days            
            let needsLogging = LoggingService.getLastVisitInDays(appointmentId) !== 0;
            LoggingService.storeLastVisitTimestamp(appointmentId);

            try {

                let _appointment: any = null;
                let _parentAppointment: any = null;
                let visitMotive: any = null;
                let landingPageData: LandingPageData | null = null;

                // get the appointment
                _appointment = await AppointmentsService.getLocationAppointment(clientId, locationId, appointmentId);

                if (_appointment) {

                    console.log(`appointment id: ${_appointment.id}`);

                    const patientName = (_appointment.patient && _appointment.patient.lastName) ? `${_appointment.patient.firstName} ${_appointment.patient.lastName} hat ` : "";

                    if (_appointment.createdBy === "campaign" && _appointment.campaignId) {
                        const campaign = await CampaignsService.getCampaign(_appointment.campaignId, clientId, locationId);
                        visitMotive = _appointment.visitMotive;
                        landingPageData = campaign.landingPage;

                        const visitMotiveName = landingPageData ? landingPageData.headline : (visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name);
                        const message = `${patientName}Kampagnen-Landingpage für ${visitMotiveName} aufgerufen.`;
                        if (needsLogging) LoggingService.log(LogItemType.campaignLandingPageLoaded, _appointment.id, _appointment.patient.id, _appointment.calendar?.userId, message, clientId, locationId);
                        needsLogging = false;

                        // Now update campaign patient for campaign statistics
                        const campaignPatientProps = {
                            id: _appointment.patient.id,
                            visitedLandingPage: true,
                            visitedLandingPageAt: new Date()
                        };
                        CampaignsService.updateCampaignPatientProperties(campaignPatientProps, _appointment.campaignId, clientId, locationId);


                    } else if (_appointment.createdBy === "recaller" && _appointment.parentRecallId) {
                        // check if we have to load the parent appointment,
                        // because there are the landing page data stored, if this is a recall or successor appointment
                        _parentAppointment = await AppointmentsService.getLocationAppointment(clientId, locationId, _appointment.parentRecallId);
                        if (_parentAppointment) {
                            visitMotive = await VisitMotivesService.getVisitMotive(_parentAppointment?.visitMotive.id, clientId, locationId);
                            landingPageData = visitMotive?.recallLandingPage;

                            const visitMotiveName = landingPageData ? landingPageData.headline : (visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name);
                            const message = `${patientName}Recall-Landingpage für ${visitMotiveName} aufgerufen.`;
                            if (needsLogging) LoggingService.log(LogItemType.recallLandingPageLoaded, _parentAppointment.id, _appointment.patient.id, _parentAppointment.calendar?.userId, message, clientId, locationId);
                            needsLogging = false;
                        }

                    } else if (_appointment.createdBy === "predecessor" && _appointment.predecessorId) {
                        // check if we have to load the parent appointment,
                        // because there are the landing page data stored, if this is a recall or successor appointment
                        _parentAppointment = await AppointmentsService.getLocationAppointment(clientId, locationId, _appointment.predecessorId);
                        if (_parentAppointment) {
                            visitMotive = await VisitMotivesService.getVisitMotive(_parentAppointment?.visitMotive.id, clientId, locationId);
                            landingPageData = visitMotive?.successorLandingPage;

                            const visitMotiveName = landingPageData ? landingPageData.headline : (visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name);
                            const message = `${patientName}Folgetermin-Landingpage für ${visitMotiveName} aufgerufen.`;
                            if (needsLogging) LoggingService.log(LogItemType.successorLandingPageLoaded, _parentAppointment.id, _parentAppointment.patient.id, _parentAppointment.calendar?.userId, message, clientId, locationId);
                            needsLogging = false;
                        }

                    } else {
                        visitMotive = await VisitMotivesService.getVisitMotive(_appointment.visitMotive.id, clientId, locationId);
                        if(visitMotive){
                            console.log(`visitMotive id: ${visitMotive.id}`);
                            landingPageData = visitMotive?.landingPage;

                            const visitMotiveName = landingPageData ? landingPageData.headline : (visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name);
                            const message = `${patientName}Termin-Landingpage für ${visitMotiveName} aufgerufen.`;
                            if (needsLogging) LoggingService.log(LogItemType.appointmentLandingPageLoaded, _appointment.id, _appointment.patient.id, _appointment.calendar?.userId, message, clientId, locationId);
                        }
                        needsLogging = false;
                    }

                } else {

                    console.log(`no appointment was found for id: ${appointmentId}`);

                    // if no appointment was found, check if it is a visitmotive just for preview the landing pages
                    if (appointmentId.toLowerCase().indexOf("campaign-") === 0) {
                        const campaignId = appointmentId.split("-")[1];

                        const campaign = await CampaignsService.getCampaign(campaignId, clientId, locationId);
                        visitMotive = await VisitMotivesService.getVisitMotive(campaign.visitMotiveId, clientId, locationId);
                        const _doctor = await UsersService.getUserByCalendarId(clientId, locationId, campaign.calendarId);

                        _appointment = {
                            id: campaignId,
                            clientId: clientId,
                            locationId: locationId,
                            patient: {
                                gender: "m",
                                firstName: "Max",
                                lastName: "Mustermann"
                            },
                            doctor: _doctor,
                            start: new Date(),
                            end: new Date(),
                            status: status ? status : "needsConfirmation",
                            visitMotive: visitMotive
                        }

                        landingPageData = campaign.landingPage;


                    } else if (appointmentId.toLowerCase().indexOf("successor-") === 0 ||
                        appointmentId.toLowerCase().indexOf("recall-") === 0 ||
                        appointmentId.toLowerCase().indexOf("appointment-") === 0) {

                        // if no appointment was found, check if it is a visitmotive just for preview the landing pages


                        needsLogging = false;

                        const visitMotiveId = appointmentId.split("-")[1];
                        const calendarId = appointmentId.split("-")[2];

                        visitMotive = await VisitMotivesService.getVisitMotive(visitMotiveId, clientId, locationId);
                        setSelectedVisitMotive(visitMotive);
                        const calendar = await CalendarsService.getCalendar(clientId, locationId, calendarId);

                        let _doctor = null;

                        if (calendar) {
                            _doctor = await UsersService.getUser(clientId, calendar.userId);
                        }

                        let id = "";

                        if (_doctor && visitMotive) {

                            if (appointmentId.toLowerCase().indexOf("successor-") === 0) {
                                landingPageData = visitMotive.successorLandingPage;
                                id = "successor";

                            } else if (appointmentId.toLowerCase().indexOf("recall-") === 0) {
                                landingPageData = visitMotive.recallLandingPage;
                                id = "recall";

                            } else {
                                landingPageData = visitMotive.landingPage;
                                id = "appointment";
                            }

                            _appointment = {
                                id: id,
                                clientId: clientId,
                                locationId: locationId,
                                patient: {
                                    gender: "m",
                                    firstName: "Max",
                                    lastName: "Mustermann"
                                },
                                doctor: _doctor,
                                start: new Date(),
                                end: new Date(),
                                status: status ? status : "needsConfirmation",
                                visitMotive: visitMotive,
                                calendar: calendar
                            }

                        }
                    }

                }

                if (!visitMotive && _appointment && _appointment.visitMotive && _appointment.visitMotive.id) {
                    visitMotive = await VisitMotivesService.getVisitMotive(_appointment.visitMotive.id, clientId, locationId);
                    setSelectedVisitMotive(visitMotive);
                }

                // if we dont have any landing page data, fill them with infos from the visit motive
                if (!landingPageData && visitMotive) {
                    landingPageData = new LandingPageData();
                    landingPageData.fromObject({
                        headline: visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name,
                        image: "",
                        beforeAfterImages: [],
                        description: "",
                        videoGroupId: "",
                        videoGroups: [],
                        showRatings: false
                    })
                }

                // no appointment found, so we can stop here
                if(!_appointment) {
                    setIsLoading(false);
                    return;
                }


                setLandingPageData(landingPageData);

                setAppointment(_appointment);

                //console.log(_appointment.patient.lastName);

                setDoctor(_appointment.doctor);

                const locationData = await ClientLocationsService.getLocation(clientId, locationId)

                if (locationData) {
                    setLocation(locationData);
                    setLocationLogoUrl(locationData.logoUrl);
                }

                const clientData = await ClientsService.getClient(clientId)

                setClient(clientData);


                if (needsLogging && visitMotive) {
                    const patientName = (_appointment.patient && _appointment.patient.lastName) ? `${_appointment.patient.firstName} ${_appointment.patient.lastName} hat ` : "";
                    const visitMotiveName = landingPageData ? landingPageData.headline : (visitMotive.nameForPatient ? visitMotive.nameForPatient : visitMotive.name);
                    const message = `${patientName}Termin-Landingpage für ${visitMotiveName} aufgerufen.`;
                    if (needsLogging) LoggingService.log(LogItemType.appointmentLandingPageLoaded, _appointment.id, _appointment.patient.id, _appointment.calendar?.userId, message, clientId, locationId);
                    needsLogging = false;
                }

                // load video groups
                if (landingPageData && (_appointment.calendar.license === "CalendR_AI" || _appointment.calendar.license === "CalendR_AI_Plus")) {
                    const _group = await ClonrService.getVideoGroupForLandingPage(landingPageData, _appointment.calendar.id, clientId);
                    setVideoGroup(_group);
                }

                setIsLoading(false);

                const startDate = getDate(_appointment.start);
                const end = moment(startDate).add(6, "weeks").toDate();

                const onlineCalendars = await CalendarsService.getCalendars(_appointment.clientId, locationId, true);
                if (onlineCalendars) {
                    const calendarIds = onlineCalendars.map(cal => {
                        if (cal.allowOnlineAppointments && cal.userId === _appointment.doctor.id) {
                            return cal.id;
                        }
                    });

                    const _appointments = await AppointmentsService.getPublicAppointments(clientId, locationId, startDate, end, calendarIds);
                    setAppointments(_appointments ?? []);
                }


            } catch (error) {
                console.error((error as any).message);
            }

        }

        setIsLoading(false);

    }

    function getVideoUrl(): string {

        if (landingPageData && doctor) {
            if (landingPageData.videos) {
                const docVid = landingPageData.videos.find(v => v.userId === doctor.id);

                if (docVid && docVid.video) {
                    return getYouTubeId(docVid.video);
                }
            }

            if (videoGroup && videoGroup.videos.length > 0) {
                return videoGroup.videos[0].videoUrl;
            }
        }

        return "";
    }

    function getVideoGroup(): VideoGroup | null {

        if (landingPageData && doctor) {
            // if (landingPageData.videos) {
            //     const docVid = landingPageData.videos.find(v => v.userId === doctor.id);

            //     if (docVid && docVid.video) {
            //         return getYouTubeId(docVid.video);
            //     }
            // }

            if (videoGroup) {
                return videoGroup;
            }
        }

        return null;
    }

    async function declineAppointment() {


        const realyDecline = window.confirm("Wollen Sie diesen Termin wirklich absagen?");

        if (realyDecline) {
            setIsDeclining(true);

            await AppointmentsService.setLocationAppointmentStatus(appointment, null, "declined");

            appointment.status = "declined";
            setAppointment(JSON.parse(JSON.stringify(appointment)));

            setIsBooking(false);

            setShowBookingConfirmation(false);

            setIsDeclining(false);
        }
    }

    async function remindAgainLater() {
        AppointmentsService.remindAgainLater(appointment);

        setShowSnoozeMessage(true);
    }

    // async function confirmAppointment() {
    //     setIsBooking(true);

    //     await AppointmentsService.confirmRecallAppointment(appointment);
    //     appointment.status = "confirmed";
    //     setAppointment(JSON.parse(JSON.stringify(appointment)));

    //     setShowBookingConfirmation(true);

    //     setIsBooking(false);
    // }




    function getHeadline(): string {

        if (landingPageData && landingPageData.headline) {
            return landingPageData.headline;
        }

        if (appointment && appointment.visitMotive) {
            return appointment.visitMotive.nameForPatient ?? appointment.visitMotive.name;
        }


        return "";
    }

    if (isLoading) {
        return (
            <LoadingCtrl show={true} message={"Ihr Termin wird geladen..."}></LoadingCtrl>
        );
    }

    if (isBooking) {
        return (
            <LoadingCtrl show={true} message={"Ihr Termin wird nun für Sie gebucht..."}></LoadingCtrl>
        );
    }

    if (isDeclining) {
        return (
            <LoadingCtrl show={true} message={"Ihr Termin wird nun abgesagt..."}></LoadingCtrl>
        );
    }

    if (appointment && appointment.status === "declined") {
        return (
            <div className="kt-appointmentPage">
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">

                            <img src={locationLogoUrl} alt="" className="kt-client-logo" />

                            <h1>Termin gelöscht.</h1>
                            <h2>Ihr Termin am {getDateString(appointment.start)} um {getTimeString(appointment.start)} wurde erfolgreich storniert.</h2>

                            <div className="kt-btn-group">
                                <a className="kt-btn kt-btn-big kt-btn-secondary-color" href={`https://pickadoc.de/profile/${clientId}/${locationId}/${doctor.id}`}>
                                    <i className="fal fa-calendar-plus" aria-hidden="true"></i>
                                    <span>Neuen Termin buchen</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (showSnoozeMessage) {
        return (
            <div className="kt-appointmentPage">
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">

                            <img src={locationLogoUrl} alt="" className="kt-client-logo" />

                            <h1>Vielen Dank!</h1>
                            <h2>Wir werden Sie wie gewünscht in einer Woche erneut erinnern.</h2>

                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="kt-appointmentPage">



            {(!isLoading && (!client || !location || !doctor || !appointment)) ? (
                <div className="kt-body">
                    <div className="kt-panel">
                        <div className="kt-panel-content">
                            <h1>Termin nicht gefunden</h1>
                            <h2>Dieser Termin wurde vielleicht schon gelöscht.</h2>
                            <h2><a href={`https://pickadoc.de/appointments`}>Klicken Sie hier um Ihre Termine zu verwalten.</a></h2>
                            {(clientId && locationId) && <h2><a href={`https://pickadoc.de/profile/${clientId}/${locationId}`}>Klicken Sie hier um einen neuen Termin zu buchen.</a></h2>}
                        </div>
                    </div>
                </div>
            ) :

                (
                    <div className="kt-body">

                        <AiChatDialog
                            visible={isChatDialogVisible}
                            title='AI Doktor Chat'
                            label='Wie kann ich Ihnen behilflich sein?'
                            avatarImageurl={doctor.avatarUrl}
                            onClose={() => setIsChatDialogVisible(false)}
                        />

                        <div className="kt-panel">
                            <div className="kt-panel-content">

                                {locationLogoUrl && <img src={locationLogoUrl} alt="" className="kt-client-logo" />}

                                {showBookingConfirmation ?
                                    <div>
                                        <h1>Terminbuchung erhalten.</h1>
                                        <h2 className="kt-content">Ihr Termin wurde erfolgreich gebucht.</h2>
                                        <p className="kt-content kt-appointment-text kt-text-box">
                                            Ihr Termin: <br /><span className="kt-highlight">{getDateString(appointment.start)}</span> um <span className="kt-highlight">{getTimeString(appointment.start)}</span>
                                        </p>
                                    </div>
                                    :
                                    ((appointment.status === "confirmed" || appointment.status === "needsConfirmation") &&
                                        <div>

                                            {appointment.needsToSignDocuments &&
                                                <div style={{ margin: "30px 0", padding: "10px 0 ", borderTop: "1px solid grey", borderBottom: "1px solid grey" }}>
                                                    <div className='kt-center'>Für Ihren Termin müssen Sie noch Dokumente ausfüllen</div>
                                                    <a
                                                        className="kt-btn-big kt-btn-secondary-color"
                                                        href={PatientsService.getDocumentsLink(appointment.patient.id, clientId!, locationId!)}
                                                        target='_blank'
                                                    >
                                                        <i className="fal fa-pen" aria-hidden="true"></i>&nbsp;
                                                        <span>Dokumente ausfüllen</span>
                                                    </a>
                                                </div>
                                            }

                                            <h1>{`${getHeadline()}`}</h1>

                                            {getVideoGroup() &&
                                                <div className="kt-landingPage-video">
                                                    <VideoGroupPlayerCtrl
                                                        id="clonRVideo"
                                                        videoGroup={getVideoGroup()!}
                                                    />
                                                </div>}

                                            <p className="kt-content" dangerouslySetInnerHTML={{ __html: landingPageData.description }}></p>

                                        </div>
                                    )
                                }

                            </div>

                        </div>



                        {landingPageData.showButtons !== false &&
                            <div className="kt-panel kt-panel-dark">
                                <div className="kt-panel-content">

                                    {appointment.status === "needsConfirmation" &&

                                        <>
                                            <p className="kt-content kt-appointment-text" >
                                                Bitte vereinbaren Sie einen neuen Termin:
                                            </p>

                                            <BookingPanel
                                                client={client}
                                                location={location}
                                                doctor={doctor}
                                                doctors={[]}
                                                appointment={appointment}
                                                visitMotive={selectedVisitMotive}
                                            />
                                        </>
                                    }

                                    {appointment.status === "confirmed" &&
                                        <>
                                            <p className="kt-content kt-appointment-text" >
                                                Ihr Termin: <br /><span className="kt-highlight">{getDateString(appointment.start)}</span> um <span className="kt-highlight">{getTimeString(appointment.start)}</span>
                                            </p>
                                            <br />
                                            <BookingPanel
                                                client={client}
                                                location={location}
                                                doctor={doctor}
                                                doctors={[]}
                                                appointment={appointment}
                                                visitMotive={selectedVisitMotive}
                                                bookingMode="postpone"
                                            />
                                        </>
                                    }


                                    <div className="kt-btn-group">

                                        {appointment.status === "needsConfirmation" &&
                                            <button className="kt-btn kt-btn-wide" onClick={remindAgainLater}>
                                                <i className="fal fa-alarm-clock kt-big" aria-hidden="true"></i>
                                                <span>Jetzt nicht, später erinnern</span>
                                            </button>
                                        }

                                        {(appointment.status === "confirmed" || appointment.status === "needsConfirmation") &&
                                            <button className="kt-btn kt-btn-big" onClick={declineAppointment} >
                                                <i className="fa fa-times kt-big" aria-hidden="true"></i>
                                                <span>Absagen</span>
                                            </button>
                                        }

                                    </div>

                                </div>
                            </div>
                        }

                        {landingPageData.image &&
                            <div className="kt-panel">
                                <div className="kt-panel-content kt-margin-auto kt-landingPage-image">
                                    <img src={landingPageData.image} alt="" />
                                </div>
                            </div>
                        }


                        <div className="kt-panel">
                            <div className="kt-panel-content kt-margin-auto">

                                <div className="kt-profile">
                                    <Avatar src={doctor.avatarUrl} alt="" width="60px" />
                                    <h1>{getFullUserName(doctor)}</h1>
                                </div>


                                <div className="kt-two-columns">
                                    <div>
                                        <h3>{`${client.name}`}</h3>
                                        <h3>{`${location.street}`}</h3>
                                        <h3>{`${location.postalCode} ${location.city}`}</h3>
                                    </div>
                                    <div>
                                        {location.googleMapsLink &&
                                            <a className="kt-btn kt-no-underline" href={location.googleMapsLink} target="_blank" rel="noopener noreferrer">
                                                <i className="fal fa-map-marker-alt" aria-hidden="true"></i>
                                                <span> Routenplaner</span>
                                            </a>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        {isChatButtonVisisble ?
                            <>
                                {doctor.id === "7OPCoghiRzwzVtXE8eOJ" ?
                                    <>
                                        <AiPhoneChatCtrl avatarImageurl={doctor.avatarUrl} />
                                    </>
                                    :
                                    <>
                                        <div className="kt-fixed-footer" onClick={() => setIsChatDialogVisible(true)}>
                                            Brauchen Sie Hilfe?
                                        </div>

                                        <Fab color="primary" aria-label="add" className='kt-fab kt-slow-pulse' onClick={() => setIsChatDialogVisible(true)}>
                                            {doctor.avatarUrl ?
                                                <Avatar src={doctor.avatarUrl} width='100%' borderColor='#24374e' />
                                                :
                                                <img src="/images/AI-Chat-Button-s.png" alt='AI ChatButton' />
                                            }

                                        </Fab>
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className="kt-fixed-footer">
                                    Brauchen Sie Hilfe?

                                    <a className="kt-btn kt-btn-secondary-color" href={`tel:${location.phoneNumber}`}>
                                        <i className="fal fa-phone" aria-hidden="true"></i>
                                        <span> {location.phoneNumber}</span>
                                    </a>

                                </div>
                            </>
                        }



                    </div>
                )}


        </div>
    );
}