import React, { useState, useEffect } from 'react';
import {
    Link
} from "react-router-dom";

import axios from 'axios';
import AudioRecorder, { AudioRecorderStatus } from '../../AudioRecorder';
import VoicePlayback from './voicePlayback';


export default function VoicePlaygroundPage() {

    const XI_API_KEY = "cc9c6633f7b52dc8ba0b6facba2cd6ed";
    const voices_url = "https://api.elevenlabs.io/v1/voices";
    const add_voice_url = "https://api.elevenlabs.io/v1/voices/add";


    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [selectedVoiceId, setSelectedVoiceId] = useState("");

    const [audioRecordingURL, setAudioRecordingURL] = useState("");
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

    const [step, setStep] = useState(0);

    const [recordingStatus, setRecordingStatus] = useState(AudioRecorderStatus.inactive);

    useEffect(() => {

        init();

    }, []);

    async function init() {
        const _customVoiceId = localStorage.getItem("audioVoiceId");


        const _formFilled = localStorage.getItem("formFilled");
        if (_formFilled) {
            setStep(2);
        }

        //localStorage.setItem("audioVoiceId", "VR6AewLTigWG4xSOukaG");

        if (_customVoiceId) {

            const _voices = await getVoices(_customVoiceId);

            setIsLoading(false);

            if (_voices && _voices.findIndex(v => v.voice_id === _customVoiceId) > -1) {
                setSelectedVoiceId(_customVoiceId);

                setStep(2);
            }

        }

    }

    function handleFinishedRecording(newAudioUrl: string, newAudioBlob: Blob) {
        setAudioRecordingURL(newAudioUrl);
        setAudioBlob(newAudioBlob);
        setRecordingStatus(AudioRecorderStatus.finished);
    }

    async function getVoices(_customVoiceId: string | null): Promise<{ voice_id: string, name: string }[] | null> {

        try {
            // Set options for the API request.
            const options = {
                method: 'GET',
                url: voices_url,
                headers: {
                    'content-type': 'application/json', // Set the content type to application/json.
                    'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
                }
            };

            // Send the API request using Axios and wait for the response.
            const result = await axios.request(options);

            if (result && result.data && result.data.voices && result.data.voices.length > 0) {
                return result.data.voices;
            }

        } catch (err) {
            alert(err);
        }

        return null;
    }

    async function addVoice(voiceName: string): Promise<string> {

        try {
            const data = {
                name: voiceName,
                files: audioBlob
            } as any;

            const options = {
                headers: {
                    'xi-api-key': `${XI_API_KEY}`, // Set the API key in the headers.
                }
            } as any;

            const result: any = await axios.postForm(add_voice_url, data, options);

            return result.data.voice_id;

        } catch (err) {
            alert(err);
        }

        return "";
    }

    //* Convert blob to base64
    const blobToData = (blob: Blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob)
        })
    }


    async function uploadRecording() {

        if (!audioBlob) return;

        // first check if the user has spoken the correct text
        var base64AudioData = await blobToData(audioBlob);
        console.log(base64AudioData);

        var reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = function () {
            var base64String = reader.result;
            console.log('Base64 String - ', base64String);
        }

        const _voiceId = await addVoice("Los Grecos");

        if (_voiceId !== "") {
            localStorage.setItem("audioVoiceId", _voiceId);

            setSelectedVoiceId(_voiceId);

            setStep(2);
        }
    }

    function StartSlide() {
        return <div className='kt-box-row-wrapper'>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ width: "100%", maxWidth: "100%", flexDirection: "column", alignItems: "center" }}>

                    <img src="../images/ai-avatar.JPG" alt="AI avatar"></img>

                    <br />

                    <h1 style={{ textAlign: "center" }}>AI Rapid Voice Cloning</h1>

                    <p>Klone deine Stimme in nur wenigen Sekunden.</p>

                    <div className="kt-form-ctrl">
                        <button className='kt-btn' onClick={() => setStep(1)}>KLONE MEINE STIMME</button>
                    </div>

                </div>
            </div>
        </div>
    }

    function RecordingSlide() {
        return <div className='kt-box-row-wrapper'>

            <div className='kt-box-row kt-box-features' style={{ margin: 0 }}>
                <div className='kt-box-row-content kt-big' style={{ width: "100%", maxWidth: "100%", flexDirection: "column", alignItems: "center" }}>

                    <span className='kt-secondary-color'>Sprechen Sie bitte nun folgenden Text:</span>
                    <p>
                        Ich bin damit einverstanden, dass meine Stimme von Pickadoc geklont wird.
                        Ich habe die volle Kontrolle über die Inhalte, die mit meiner Stimme generiert werden.
                        Das Ziel besteht darin, mit ein paar Sekunden Audio synthetische Stimmen zu erzeugen, die genau wie ich klingen.
                        Generierte Inhalte können für Videos, Videoavatare, Instagram, YouTube und vieles mehr verwendet werden.
                    </p>

                    <AudioRecorder onFinishedRecording={(newAudioUrl, newBlob) => handleFinishedRecording(newAudioUrl, newBlob)} />

                    {audioRecordingURL && <audio src={audioRecordingURL} controls />}

                    <br />
                    {recordingStatus === AudioRecorderStatus.finished &&
                        <div className="kt-form-ctrl">
                            <button className='kt-btn' onClick={() => uploadRecording()}>WEITER</button>
                        </div>
                    }


                </div>
            </div>
        </div>
    }



    return (

        <div className="kt-homePageNew kt-voicePlaygroundPage">

            <div className="kt-header">

                <div className="kt-header-wrapper">
                    <div className='kt-logo-wrapper'>
                        <a href="https://pickadoc.de"><img src="../images/logo-monochrome.svg" width={55} alt="logo" /></a>
                        <a href="https://pickadoc.de"><span className='kt-logo-text'>Pickadoc</span></a>
                    </div>

                    <nav
                        className={isMobileMenuVisible ? "kt-mobile-menu" : ""}
                        onClick={() => setIsMobileMenuVisible(false)}
                    >
                        <ul>
                            <li><a href="../#appointments">Recaller</a></li>
                            <li><a href="../#psign">p-sign</a></li>
                            <li><a href="../#content_pack">Content Pack</a></li>
                            <li><a href="../#livebot">Live Bot</a></li>
                            <li><a href="../#prices">Preise</a></li>
                            <li className='kt-mobile'><a href="https://cal.pickadoc.de">Login</a></li>
                        </ul>
                    </nav>

                    <div className="kt-right-column">
                        <a href="https://cal.pickadoc.de" className='kt-desktop'>Login</a>
                        <a href="../installation" className='kt-btn kt-desktop'><span>REGISTRIEREN</span></a>
                        <i
                            className='fas fa-bars kt-mobile'
                            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
                        ></i>
                    </div>
                </div>
            </div>

            <div className='kt-home-body'>
                <img src="../images/playground.svg" style={{ width: "200px", position: "absolute", top: "92px" }} alt="AI avatar"></img>

                {step === 0 && <StartSlide />}
                {step === 1 && <RecordingSlide />}
                {step === 2 && <VoicePlayback voiceId={selectedVoiceId} />}

            </div>

            <div className='kt-footer'>
                <div className='kt-footer-wrapper'>
                    <div className='kt-logo-wrapper'>
                        <img src="../images/logo-white.svg" width={32} alt="logo" />
                        <span className='kt-logo-text'>Pickadoc</span>
                    </div>

                    <div className='kt-footer-links'>
                        <Link to={`/contact`}>Kontakt</Link>
                        •
                        <Link to={`/terms`}>AGB</Link>
                        •
                        <Link to={`/legal-notice`}>Impressum</Link>
                        •
                        <Link to={`/data-protection`}>Datenschutz</Link>
                        •
                        <a href="https://www.instagram.com/pickadoc.ai/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" style={{ fontSize: "20px" }}></i></a>
                    </div>
                    <div>
                        &copy; 2024 Pickadoc GmbH
                    </div>
                </div>
            </div>


        </div >
    );
}