import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyCG65EB0gojy6cIUO23eJ1gM2mMi7DjLLI",
    authDomain: "docgenda.firebaseapp.com",
    databaseURL: "https://docgenda.firebaseio.com",
    projectId: "docgenda",
    storageBucket: "docgenda.appspot.com",
    messagingSenderId: "659741174605",
    appId: "1:659741174605:web:495b33c5dcbd401e0ae504",
    measurementId: "G-QEWC42M632"
};

const firebaseApp = initializeApp(config);


// Initialize Analytics and get a reference to the service
//export const analytics = firebase.analytics();

initializeFirestore(firebaseApp, { experimentalAutoDetectLongPolling: true })

export default firebaseApp;
